var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getLawList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.nationFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "nationFlag",
                    label: "국내/외 구분",
                  },
                  model: {
                    value: _vm.searchParam.nationFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "nationFlag", $$v)
                    },
                    expression: "searchParam.nationFlag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "REGULATION_BILL_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "regulationBillCd",
                    label: "규제법규",
                  },
                  model: {
                    value: _vm.searchParam.regulationBillCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "regulationBillCd", $$v)
                    },
                    expression: "searchParam.regulationBillCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "규제항목 목록",
                  tableId: "table",
                  columnSetting: false,
                  isFullScreen: false,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getLawList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "신규", icon: "add" },
                                  on: { btnClicked: _vm.addLaw },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveLaw,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              codeGroupCd: "REGULATION_BILL_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "regulationBillCd",
                              label: "규제법규",
                              editable: _vm.editable && _vm.dataeditable,
                            },
                            model: {
                              value: _vm.data.regulationBillCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regulationBillCd", $$v)
                              },
                              expression: "data.regulationBillCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "규제항목코드",
                              name: "regulatedItemCd",
                            },
                            model: {
                              value: _vm.data.regulatedItemCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regulatedItemCd", $$v)
                              },
                              expression: "data.regulatedItemCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "규제항목 명",
                              name: "regulatedItemName",
                            },
                            model: {
                              value: _vm.data.regulatedItemName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regulatedItemName", $$v)
                              },
                              expression: "data.regulatedItemName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              counter: true,
                              maxlength: 500,
                              editable: _vm.editable && _vm.dataeditable,
                              rows: 5,
                              label: "규제 설명",
                              name: "regulationDesc",
                            },
                            model: {
                              value: _vm.data.regulationDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regulationDesc", $$v)
                              },
                              expression: "data.regulationDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.nationFlagItems,
                              label: "국내/외구분",
                              name: "nationFlag",
                            },
                            model: {
                              value: _vm.data.nationFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "nationFlag", $$v)
                              },
                              expression: "data.nationFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              isFlag: true,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }